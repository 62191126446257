<template>
    <div>

        <div class="preloader" v-if="isLoading">
            <img src="/img/backoffice/preloader.gif" />
        </div>
        <div v-else>
            <sui-table compact="very" striped>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>{{ $store.state.adminLanguage['name2'] }}</sui-table-header-cell>
                        <sui-table-header-cell :width="4"></sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>

                <sui-table-body>

                    <sui-table-row v-for="(countryPreset, index) of countryPresets">
                        <sui-table-cell single-line>
                            {{ countryPreset.name }}
                        </sui-table-cell>

                        <sui-table-cell text-align="right">
                            <router-link is="sui-button" icon basic :to="{name: 'GameCountriesEdit', params: {countryId: countryPreset.id}}">
                                <sui-icon color="blue" name="edit" />
                            </router-link>

                            <sui-button icon basic v-on:click="deleteCountryPreset(countryPreset.id, index)">
                                <sui-icon color="red" name="close" />
                            </sui-button>
                        </sui-table-cell>

                    </sui-table-row>

                </sui-table-body>
            </sui-table>
        </div>

    </div>
</template>

<script>
    import CommonMixin from '../../mixins/commonMixin';
    import TableMixin from '../../mixins/tableMixin';

    export default {
        name: 'GameCountriesList',
        mixins: [CommonMixin, TableMixin],
        data () {
            return {
                countryPresets: [],
                isLoading: true,
            }
        },

        methods: {
            getData() {
                this.axios.get('/api/backoffice/country_presets/list').then((response) => {
                    this.countryPresets = response.data;
                    this.isLoading = false;
                })
            },
            deleteCountryPreset(id, index) {
                let data = {
                    item: {
                        id: id,
                    },
                };
                let that = this;
                this.axios.post('/api/backoffice/country_presets/delete', data).then((response) => {
                    that.$delete(that.countryPresets, index);
                })
            },
        },
        beforeMount() {
            this.getData();
        },
        mounted () {

        },
        created() {
            this.checkAuth();
        }
    }
</script>


