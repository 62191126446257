<template>
    <div>

        <sui-form>
            <sui-header>{{ $store.state.adminLanguage['main_info'] }}</sui-header>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['name'] }}</label>
                <input
                    type="text"
                    :placeholder="$store.state.adminLanguage['name']"
                    v-model="star.name"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>Описание</label>
                <textarea
                    type="text"
                    v-model="star.description"
                ></textarea>
            </sui-form-field>

            <sui-form-field width="eight">
                <label>Скандал</label>
                <textarea
                    type="text"
                    v-model="star.paparazzi_text"
                ></textarea>
            </sui-form-field>

            <sui-form-field width="eight">
                <label>Минимальная ставка</label>
                <input
                    type="text"
                    :placeholder="$store.state.adminLanguage['star_min_fee']"
                    v-model="star.min_fee"
                />
            </sui-form-field>

            <sui-form-field width="eight" class="backoffice-file-upload">
                <label>Фото</label>
                <input type="file" :ref="'image'" hidden v-on:change="uploadImage($event)" />
                <img class="backoffice-star-upload__button" :src="star.image" v-on:click="pickImage()" />
            </sui-form-field>

            <sui-form-field width="eight" class="backoffice-file-upload">
                <label>Скандал</label>
                <input type="file" :ref="'imagePaparazzi'" hidden v-on:change="uploadImagePaparazzi($event)" />
                <img class="backoffice-star-upload__button" :src="star.paparazzi_image" v-on:click="pickImagePaparazzi()" />
            </sui-form-field>


            <sui-header>Успешность в жанрах</sui-header>
            <sui-form-field width="eight" v-for="genre in genres">
                <label>{{ genre.name }}</label>
                <input
                    type="text"
                    v-model="starGenres[genre.id]"
                />
            </sui-form-field>




            <sui-form-field>
                <br>
                <sui-button basic positive v-on:click="update()">{{ $store.state.adminLanguage['update'] }}</sui-button>
            </sui-form-field>
        </sui-form>

    </div>
</template>

<script>

export default {
    name: 'GameStarsEdit',
    data () {
        return {
            starID: null,
            star: {},
            genres: [],
            starGenres: {},

            uploadingBackground: null,

            errors: null,
            loading: {
                update: false,
            },
        }
    },

    methods: {
        update() {
            this.loading.update = true;
            let data = {
                star: this.star,
                starGenres: this.starGenres,
            };
            let that = this;
            this.axios.post('/api/backoffice/stars/update', data)
                .then((response) => {
                    that.$router.push({name: 'GameStarsList'})
                })
                .catch(function (error) {
                    that.errors = error.response.data.errors;
                    that.loading.update = false;
                })
        },

        getStar() {
            this.axios.get('/api/backoffice/stars/get', {
                params: {
                    id: this.starID,
                }
            }).then((response) => {
                this.star = response.data.star;
                this.genres = response.data.genres;
                this.starGenres = response.data.starsGenres;
            })
        },

        pickImage() {
            this.$refs['image'].click()
        },

        pickImagePaparazzi() {
            this.$refs['imagePaparazzi'].click()
        },

        uploadImage(event) {
            this.uploadingImage = event.target.files[0];
            let data = new FormData();
            data.append('image', this.uploadingImage);

            this.axios.post('/api/backoffice/upload_actor', data)
                .then((response) => {
                    this.star.image = response.data;
                })
        },

        uploadImagePaparazzi(event) {
            this.uploadingImage = event.target.files[0];
            let data = new FormData();
            data.append('image', this.uploadingImage);

            this.axios.post('/api/backoffice/upload_paparazzi', data)
                .then((response) => {
                    this.star.paparazzi_image = response.data;
                })
        },

    },
    mounted() {
        this.starID = this.$route.params.starId;
        this.getStar();
    }
}
</script>


