<template>
    <div>
        <div class="preloader" v-if="isLoading">
            <img src="/img/backoffice/preloader.gif" />
        </div>
        <div v-else>
            <sui-table compact="very" striped>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>Язык</sui-table-header-cell>
                        <sui-table-header-cell>Очередность</sui-table-header-cell>
                        <sui-table-header-cell>{{ $store.state.adminLanguage['name'] }}</sui-table-header-cell>
                        <sui-table-header-cell :width="4"></sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>

                <sui-table-body>

                    <sui-table-row v-for="(star, index) of stars">
                        <sui-table-cell single-line>
                            {{ star.language }}
                        </sui-table-cell>
                        <sui-table-cell single-line>
                            {{ star.order }}
                        </sui-table-cell>
                        <sui-table-cell single-line>
                            {{ star.name }}
                        </sui-table-cell>

                        <sui-table-cell text-align="right">
                            <router-link is="sui-button" icon basic :to="{name: 'GameStarsEdit', params: {starId: star.id}}">
                                <sui-icon color="blue" name="edit" />
                            </router-link>

                            <sui-button icon basic v-on:click="deleteStar(star.id, index)">
                                <sui-icon color="red" name="close" />
                            </sui-button>
                        </sui-table-cell>

                    </sui-table-row>

                </sui-table-body>
            </sui-table>
        </div>

    </div>
</template>

<script>
import CommonMixin from '../../mixins/commonMixin';
import TableMixin from '../../mixins/tableMixin';

export default {
    name: 'GameStarsList',
    mixins: [CommonMixin, TableMixin],
    data () {
        return {
            stars: [],
            isLoading: true,
        }
    },

    methods: {
        getData() {
            this.axios.get('/api/backoffice/stars/list').then((response) => {
                this.stars = response.data;
                this.isLoading = false;
            })
        },
        deleteStar(id, index) {
            let data = {
                item: {
                    id: id,
                },
            };
            this.axios.post('/api/backoffice/stars/delete', data).then((response) => {
                this.$delete(this.stars, index);
            })
        },
    },
    beforeMount() {
        this.getData();
    },
    mounted () {

    },
    created() {
        this.checkAuth();
    }
}
</script>


