<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
    import CommonMixin from './mixins/commonMixin';

    export default {
        name: 'App',
        mixins: [CommonMixin],
        data() {
            return {

            }
        },
        computed: {
            render: function() {
                let render = false;
                if ((this.isAuthChecked && !this.isAuth) || (this.isAuthChecked && this.user)) {
                    render = true;
                }

                return render;
            },
        },
        methods: {

        },
        created() {

        },
    }
</script>
