import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    adminLanguage: {},
  },
  getters: {
    getUser: state => {
      return state.user;
    },
    getAdminLanguage: state => {
      return state.adminLanguage;
    },
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    setAdminLanguage(state, payload) {
      state.adminLanguage = payload;
    },
  }
});
