<template>
    <div>
        <sui-form>
            <sui-header>{{ host.name }} ({{ host.email }})</sui-header>

            <sui-table compact="very" striped>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>{{ $store.state.adminLanguage['name2'] }}</sui-table-header-cell>
                        <sui-table-header-cell>{{ $store.state.adminLanguage['created'] }}</sui-table-header-cell>
                        <sui-table-header-cell></sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>

                <sui-table-body>

                    <sui-table-row v-for="(game, index) of host.games">
                        <sui-table-cell>
                            <router-link is="sui-button" icon basic :to="{name: 'HostGamesControl', params: {gameId: game.id}}">
                                <i class="flag" v-bind:class="game.language.code"></i>
                                {{ game.title }}
                            </router-link>
                        </sui-table-cell>

                        <sui-table-cell>
                            {{ new Date(game.created_at).toLocaleDateString() }}
                        </sui-table-cell>

                        <sui-table-cell text-align="right">
                            <sui-button icon basic v-on:click="deleteGame(game.id, index)">
                                <sui-icon color="red" name="close" />
                            </sui-button>
                        </sui-table-cell>

                    </sui-table-row>

                </sui-table-body>
            </sui-table>

        </sui-form>

    </div>
</template>

<script>

export default {
    name: 'GameHost',
    data () {
        return {
            host: {},
            id: null,
            isLoading: false,
        }
    },

    methods: {

        getHostDetail(id) {
            this.axios.get('/api/backoffice/hosts/detail', {
                params: {
                    id: id
                }
            }).then((response) => {
                this.host = response.data;
                this.isLoading = false;
            })
        },

        deleteGame(id, index) {
            let data = {
                item: {
                    id: id,
                },
            };
            this.axios.post('/api/backoffice/host/games/delete', data).then((response) => {
                this.$delete(this.host.games, index);
            })
        },
    },
    mounted() {
        this.id = this.$route.params.id;
        this.getHostDetail(this.id);
    }
}
</script>
