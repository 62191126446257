<template>
    <div>

        <div class="ui top attached tabular menu">

            <router-link
                :to="{name: 'GameLanguagesList'}"
                is="sui-menu-item"
                :active="activeSection === 'GameLanguagesList'"
                :content="$store.state.adminLanguage['languages_list']"
                v-on:click.native="setActiveSection('GameLanguagesList')"
            />

            <div class="right menu">
                <router-link
                    :to="{name: 'GameLanguagesAdd'}"
                    is="sui-menu-item"
                    :active="activeSection === 'GameLanguagesAdd'"
                    :content="$store.state.adminLanguage['add_language']"
                    v-on:click.native="setActiveSection('GameLanguagesAdd')"
                />
            </div>

        </div>

        <div class="ui bottom attached segment padded">
            <router-view></router-view>
        </div>

    </div>
</template>

<script>
    import SectionMixin from '../mixins/sectionMixin';

    export default {
        name: 'GameLanguages',
        mixins: [SectionMixin],
        data () {
            return {

            }
        },
        methods: {

        },
        mounted() {

        }
    }
</script>


