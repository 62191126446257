<template>
    <div v-if="render">

        <sui-menu fixed inverted v-if="$route.name !== 'HostGamesControl'">
            <sui-container>

                <!-- Common Dashboard Link -->
                <router-link :to="{name: 'Dashboard'}" is="sui-menu-item" class="header">
                    <game-logo
                        :size="[217, 73]"
                        :lang="$store.state.adminLanguage['language']"
                    ></game-logo>
                </router-link>

                <!-- Host Menu -->
                <template v-if="user.role == 'host'">
                    <router-link is="sui-menu-item" :to="{name: 'HostGamesList'}">{{ $store.state.adminLanguage['my_games'] }}</router-link>
                </template>

                <!-- Admin Menu -->
                <template v-if="user.role == 'admin'">
                    <router-link is="sui-menu-item" :to="{name: 'GameHostsList'}">{{ $store.state.adminLanguage['hosts'] }}</router-link>
                    <router-link is="sui-menu-item" :to="{name: 'GameVariablesList'}">{{ $store.state.adminLanguage['game_variables'] }}</router-link>
                    <sui-dropdown :text="$store.state.adminLanguage['content']" item class="simple">
                        <sui-dropdown-menu>
                            <router-link is="sui-menu-item" :to="{name: 'GameCountriesList'}">{{ $store.state.adminLanguage['countries'] }}</router-link>
                            <router-link is="sui-menu-item" :to="{name: 'GameGenresList'}">{{ $store.state.adminLanguage['genres'] }}</router-link>
                            <router-link is="sui-menu-item" :to="{name: 'GameStarsList'}">{{ $store.state.adminLanguage['stars'] }}</router-link>
                        </sui-dropdown-menu>
                    </sui-dropdown>
                    <sui-dropdown :text="$store.state.adminLanguage['localization']" item class="simple">
                        <sui-dropdown-menu>
                            <router-link is="sui-menu-item" :to="{name: 'GameLanguagesList'}">{{ $store.state.adminLanguage['localization_game'] }}</router-link>
                            <router-link is="sui-menu-item" :to="{name: 'AdminLanguagesList'}">{{ $store.state.adminLanguage['localization_backoffice'] }}</router-link>
                        </sui-dropdown-menu>
                    </sui-dropdown>
                </template>

                <!-- User Menu -->
                <sui-menu-menu position="right">
                    <sui-dropdown item v-bind:text="user.email" class="simple">
                        <sui-dropdown-menu>

                            <!-- Admin Language Submenu -->
                            <sui-dropdown-item>
                                <sui-icon name="dropdown" /> {{ $store.state.adminLanguage['admin_language'] }}
                                <sui-dropdown-menu class="simple">
                                    <a is="sui-dropdown-item"
                                       v-for="language in adminLanguagesOptions"
                                       v-on:click="setAdminLanguage(language.value)"
                                    >{{ language.text }}</a>
                                </sui-dropdown-menu>
                            </sui-dropdown-item>

                            <a is="sui-dropdown-item" v-on:click="logout()">{{ $store.state.adminLanguage['logout'] }}</a>
                        </sui-dropdown-menu>
                    </sui-dropdown>
                </sui-menu-menu>


            </sui-container>
        </sui-menu>

        <template v-if="$route.name === 'HostGamesControl'">
            <router-view></router-view>
        </template>

        <template v-else>
            <sui-container class="main">
                <router-view></router-view>
                <sui-divider hidden />
            </sui-container>
        </template>


    </div>
</template>

<script>
    import './../scss/index.scss';
    import 'semantic-ui-css/semantic.min.css';
    import CommonMixin from './../mixins/commonMixin';
    import GameLogo from "../components/GameLogo/GameLogo";

    export default {
        name: 'App',
        components: {
            GameLogo,
        },
        mixins: [CommonMixin],
        data() {
            return {
                adminLanguagesOptions: [],
                isAuthChecked: false,
                isAuth: false,
            }
        },
        computed: {
            render: function() {
                let render = false;
                if ((this.isAuthChecked && !this.isAuth) || (this.isAuthChecked && this.user)) {
                    render = true;
                }

                return render;
            },
        },
        methods: {

            getAdminLanguagesOptions() {
                this.axios.get('/api/backoffice/get_admin_languages_options')
                    .then((response) => {
                        this.adminLanguagesOptions = response.data;
                    });
            },

            setAdminLanguage(language) {
                let data = {
                    language: language,
                }
                this.axios.post('/api/backoffice/set_admin_language', data)
                    .then((response) => {
                        this.getAdminLanguageMessages();
                    })
            },

            getAdminLanguageMessages() {
                this.axios.get('/api/backoffice/get_admin_language_messages')
                    .then((response) => {
                        this.$store.commit('setAdminLanguage', response.data);
                        //this.adminLanguageMessages = response.data;

                        console.log(response.data);
                    });
            },

            logout() {
                let that = this;
                this.axios.get('/api/backoffice/user/logout').then((response) => {
                    that.$router.push({name: 'Login'})
                })
            },
        },
        mounted: function () {
            this.getAdminLanguagesOptions();
            this.getAdminLanguageMessages();
        },
        beforeCreate: function () {
            let that = this;
            this.axios.get('/api/backoffice/check_auth').then((response) => {
                that.isAuthChecked = true;
                that.isAuth = true;

                this.getUser();
            }).catch((error) => {
                that.isAuthChecked = true;
                that.isAuth = false;
                if (error.response.status === 401) {
                    /* @todo исправить ошибку в консоли при перенаправлении с Login на Login */
                    that.$router.push({name: 'Login'})
                }
            })
        }
    }
</script>
