<template>
    <div>

        <div class="preloader" v-if="isLoading">
            <img src="/img/backoffice/preloader.gif" />
        </div>
        <div v-else>
            <sui-table compact="very" striped>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>{{ $store.state.adminLanguage['code'] }}</sui-table-header-cell>
                        <sui-table-header-cell>{{ $store.state.adminLanguage['name2'] }}</sui-table-header-cell>
                        <sui-table-header-cell></sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>

                <sui-table-body>

                    <sui-table-row v-for="(item, index) of data">

                        <sui-table-cell>
                            <i class="flag" v-bind:class="item.code"></i>
                            {{ item.code }}
                        </sui-table-cell>

                        <sui-table-cell>
                            {{ item.name }}
                        </sui-table-cell>

                        <sui-table-cell text-align="right">
                            <router-link is="sui-button" icon basic :to="{name: 'AdminLanguagesEdit', params: {id: item.id}}">
                                <sui-icon color="blue" name="edit" />
                            </router-link>

                            <sui-button icon basic v-on:click="deleteLanguage(item.id, index)">
                                <sui-icon color="red" name="close" />
                            </sui-button>
                        </sui-table-cell>

                    </sui-table-row>

                </sui-table-body>
            </sui-table>
        </div>

    </div>
</template>

<script>
import CommonMixin from '../../mixins/commonMixin';
import TableMixin from '../../mixins/tableMixin';

export default {
    name: 'AdminLanguagesList',
    mixins: [CommonMixin, TableMixin],
    data () {
        return {
            data: [],
            isLoading: true,
        }
    },

    methods: {
        getAdminLanguagesList() {
            this.axios.get('/api/backoffice/admin_languages/list').then((response) => {
                this.data = response.data;
                this.isLoading = false;
            })
        },
        deleteLanguage(id, index) {
            let data = {
                language: {
                    id: id,
                },
            };
            let that = this;
            this.axios.post('/api/backoffice/admin_languages/delete', data).then((response) => {
                that.$delete(that.data, index);
            })
        }
    },
    beforeMount() {
        this.getAdminLanguagesList();
    },
}
</script>


