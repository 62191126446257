
export default {
    data () {
        return {
            activeSection: null,
        }
    },
    methods: {
        setActiveSection(section) {
            console.log('setActiveSection!');
            this.activeSection = section;
        }
    },
    created() {

    },
};
