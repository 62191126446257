<template>
    <div>
        <sui-form>
            <sui-header>{{ $store.state.adminLanguage['main_info'] }}</sui-header>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['name'] }}</label>
                <input
                    type="text"
                    :placeholder="$store.state.adminLanguage['name']"
                    v-model="star.name"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>Описание</label>
                <textarea
                    type="text"
                    :placeholder="$store.state.adminLanguage['star_description']"
                    v-model="star.description"
                ></textarea>
            </sui-form-field>

            <sui-form-field width="eight">
                <label>Скандал</label>
                <textarea
                    type="text"
                    v-model="star.paparazzi_text"
                ></textarea>
            </sui-form-field>

            <sui-form-field width="eight">
                <label>Минимальная ставка</label>
                <input
                    type="text"
                    :placeholder="$store.state.adminLanguage['star_min_fee']"
                    v-model="star.min_fee"
                />
            </sui-form-field>

            <sui-form-field width="eight" class="backoffice-file-upload">
                <label>Фото</label>
                <input type="file" :ref="'image'" hidden v-on:change="uploadImage($event)" />
                <img class="backoffice-star-upload__button" :src="star.image" v-on:click="pickImage()" />
            </sui-form-field>

            <sui-form-field>
                <br>
                <sui-button basic positive v-on:click="add()">{{ $store.state.adminLanguage['add'] }}</sui-button>
            </sui-form-field>
        </sui-form>

    </div>
</template>

<script>

export default {
    name: 'GameStarsAdd',
    data () {
        return {
            star: {},
            uploadingImage: null,
        }
    },

    methods: {
        add() {
            let data = {
                star: this.star,
            };
            let that = this;
            this.axios.post('/api/backoffice/stars/add', data).then((response) => {
                that.$parent.activeSection = 'GameStarsList';
                that.$router.push({name: 'GameStarsList'})
            })
        },

        pickImage() {
            this.$refs['image'].click()
        },

        uploadImage(event) {
            this.uploadingImage = event.target.files[0];
            let data = new FormData();
            data.append('image', this.uploadingImage);

            this.axios.post('/api/backoffice/upload_actor', data)
                .then((response) => {
                    this.star.image = response.data;
                })
        },
    },
}
</script>


