<template>
    <div>

        <div class="ui form">

            <h2 is="sui-header">{{ $store.state.adminLanguage['main_info'] }}</h2>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['name2'] }}</label>
                <input
                    type="text"
                    :placeholder="$store.state.adminLanguage['name2']"
                    v-model="game.title"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['game_preset'] }}</label>
                <sui-dropdown
                    :options="variablesPresetsOptions"
                    :placeholder="$store.state.adminLanguage['choose_preset']"
                    search
                    selection
                    v-model="game.preset"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['game_language'] }}</label>
                <sui-dropdown
                    :options="languagesOptions"
                    :placeholder="$store.state.adminLanguage['choose_language']"
                    search
                    selection
                    v-model="game.language"
                />
            </sui-form-field>

            <h3 is="sui-header" v-if="countriesOptionsAvailable.length">{{ $store.state.adminLanguage['countries'] }}</h3>
            <sui-form-field width="eight">
                <div class="backoffice-countries">
                    <div class="backoffice-countries__item" v-for="item in countriesOptionsAvailable">
                        <sui-checkbox
                            :label="item.text"
                            :value="item.value"
                            v-model="teams"
                            :disabled="teams.length >= 9 && teams.indexOf(item.value) === -1"
                        />
                    </div>
                </div>
            </sui-form-field>

            <div class="backoffice-button-with-text">
                <div class="backoffice-button-with-text__text">
                    <p>{{ $store.state.adminLanguage['teams_limit'] }}</p>
                </div>
            </div>

            <h2 is="sui-header">Модификации</h2>
            <sui-form-field width="eight">
                <div class="backoffice-countries">
                    <div class="backoffice-countries__item">
                        <sui-checkbox
                            :label="'Брендирование'"
                            :value="'nabix'"
                            v-model="mods"
                        />
                    </div>
                </div>
            </sui-form-field>

            <!--
            <h2 is="sui-header">{{ $store.state.adminLanguage['mods'] }}</h2>
            -->

            <sui-message error visible v-if="errors">
                <sui-message-list>
                    <sui-message-item v-for="error in errors">
                        {{ error[0] }}
                    </sui-message-item>
                </sui-message-list>
            </sui-message>

            <sui-form-field>
                <br>
                <sui-button color="green" v-bind="{loading: loading.create}" v-on:click="addGame()">{{ $store.state.adminLanguage['add_game'] }}</sui-button>
            </sui-form-field>
        </div>

    </div>
</template>

<script>

export default {
    name: 'HostGamesAdd',
    data () {
        return {
            game: {},
            teams: [],
            mods: [],

            errors: null,
            loading: {
                addGame: false,
            },

            user: null,
            languagesOptions: [],
            countriesOptions: [],
            variablesPresetsOptions: [],
        }
    },

    computed: {

        countriesOptionsAvailable: function () {
            let countriesOptionsAvailable = [];
            if (this.game.language) {
                this.countriesOptions.forEach(function (countryPreset) {
                    if (countryPreset.languages.indexOf(this.game.language) !== -1) {
                        countryPreset.text = countryPreset.translations[this.game.language].value;
                        countriesOptionsAvailable.push(countryPreset);
                    }
                }, this);

                return countriesOptionsAvailable;
            } else {

                return [];
            }
        },

        /*
        canAddTeam: function() {

            return (this.teams.length < 9);
        },
        */
    },

    methods: {

        addGame() {
            this.loading.addGame = true;
            this.errors = null;
            let data = {
                game: this.game,
                teams: this.teams,
                mods: this.mods,
            };
            let that = this;
            this.axios.post('/api/backoffice/host/games/add', data)
                .then((response) => {
                    that.$router.push({name: 'HostGamesList'})
                })
                .catch(function (error) {
                    that.errors = error.response.data.errors;
                    that.loading.addGame = false;
                })
        },

        /*
        addTeam() {
            let team = {

            };
            this.teams.push(team)
        },
        */

        getLanguagesOptions() {
            this.axios.get('/api/backoffice/get_languages_options')
                .then((response) => {
                    this.languagesOptions = response.data;
                    this.languagesOptions.forEach(element => {
                        if (element.key === this.user.locale) {
                            // this.game.language = element.value;
                        }
                    });
                });
        },

        getCountriesOptions() {
            this.axios.get('/api/backoffice/get_countries_options')
                .then((response) => {
                    this.countriesOptions = response.data;
                });
        },

        getVariablesPresetsOptions() {
            this.axios.get('/api/backoffice/get_variables_presets_options')
                .then((response) => {
                    this.variablesPresetsOptions = response.data;
                });
        },

    },
    mounted() {
        this.user = this.$store.getters.getUser;

        this.getLanguagesOptions();
        this.getCountriesOptions();
        this.getVariablesPresetsOptions();

        /*
        for (let i = 0; i < 3; i++) {
            this.addTeam();
        }
        */

    }
}
</script>


