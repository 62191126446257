<template>
    <div>
        <sui-form>

            <sui-message error visible v-if="errors">
                <sui-message-list>
                    <sui-message-item v-for="error in errors">
                        {{ error[0] }}
                    </sui-message-item>
                </sui-message-list>
            </sui-message>

            <sui-header>{{ $store.state.adminLanguage['main_info'] }}</sui-header>

            <sui-form-field width="eight">
                <label>E-mail</label>
                <input
                    type="text"
                    placeholder="Email"
                    v-model="host.email"
                />
            </sui-form-field>

            <sui-form-fields>

                <sui-form-field width="eight">
                    <label>{{ $store.state.adminLanguage['name'] }}</label>
                    <input
                        type="text"
                        :placeholder="$store.state.adminLanguage['name']"
                        v-model="host.name"
                    />
                </sui-form-field>

                <sui-form-field width="eight">
                    <label>{{ $store.state.adminLanguage['company'] }}</label>
                    <input
                        type="text"
                        :placeholder="$store.state.adminLanguage['company']"
                        v-model="host.company"
                    />
                </sui-form-field>

            </sui-form-fields>

            <sui-form-fields>

                <sui-form-field width="eight">
                    <label>{{ $store.state.adminLanguage['password'] }}</label>
                    <input
                        type="password"
                        :placeholder="$store.state.adminLanguage['password']"
                        v-model="host.password"
                    />
                </sui-form-field>

                <sui-form-field width="eight">
                    <label>{{ $store.state.adminLanguage['password_confirm'] }}</label>
                    <input
                        type="password"
                        :placeholder="$store.state.adminLanguage['password_confirm']"
                        v-model="host.password_confirmation"
                    />
                </sui-form-field>

            </sui-form-fields>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['description'] }}</label>
                <textarea rows="3" v-model="host.description"></textarea>
            </sui-form-field>

            <sui-form-field>
                <br>
                <sui-button positive v-bind="{loading: loading.add}" v-on:click.prevent="add()">{{ $store.state.adminLanguage['add'] }}</sui-button>
            </sui-form-field>
        </sui-form>

    </div>
</template>

<script>

    export default {
        name: 'GameHostsAdd',
        data () {
            return {
                host: {},
                errors: null,
                loading: {
                    add: false,
                }
            }
        },

        methods: {
            add() {
                this.loading.add = true;
                this.errors = null;
                let data = this.host;
                let that = this;
                this.axios.post('/api/backoffice/hosts/add', data)
                    .then((response) => {
                        that.$router.push({name: 'GameHostsList'})
                    })
                    .catch(function (error) {
                        that.errors = error.response.data.errors;
                        that.loading.add = false;
                    })
            },
        },
    }
</script>


