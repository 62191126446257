<template>
    <div class="production-button">
        <div class="production-button__inner-wrap">
            <div class="production-button__inner-contents">
                <template v-if="stage === 'production'">
                    {{ $parent.langMessagesValues['control_stage_production'] }}
                </template>
                <template v-if="stage === 'presentation'">
                    {{ $parent.langMessagesValues['control_stage_presentation'] }}
                </template>
                <template v-if="stage === 'voting'">
                    {{ $parent.langMessagesValues['control_stage_voting'] }}
                </template>
                <template v-if="stage === 'final'">
                    {{ $parent.langMessagesValues['control_stage_final'] }}
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'control-stage-counter',
    props: ['stage'],
}
</script>
