
export default {

    data() {
        return {
            user: null,
        }
    },

    methods: {
        checkAuth() {
            let that = this;
            this.axios.get('/api/backoffice/check_auth').catch((error) => {
                if (error.response.status === 401) {
                    that.$router.push({name: 'Login'})
                }
            })
        },

        getUser() {
            this.axios.get('/api/backoffice/user').then((response) => {
                this.user = response.data;
                this.$store.commit('setUser', response.data);
            })
        }

    }
};
