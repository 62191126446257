<template>
    <div>
        <sui-form>
            <sui-header>{{ $store.state.adminLanguage['main_info'] }}</sui-header>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['name2'] }}</label>
                <input
                    type="text"
                    :placeholder="$store.state.adminLanguage['name2']"
                    v-model="variablesPreset.name"
                />
            </sui-form-field>

            <sui-header>{{ $store.state.adminLanguage['variables_values'] }}</sui-header>

            <sui-form-field width="eight" v-for="variable in variables">
                <label>{{ variable['name'] }}</label>
                <input
                    type="text"
                    :placeholder="variable['default_value']"
                    v-model="variablesPreset.values[variable['id']].value"
                />
            </sui-form-field>

            <sui-form-field>
                <br>
                <sui-button basic positive v-on:click="update()">{{ $store.state.adminLanguage['update'] }}</sui-button>
            </sui-form-field>
        </sui-form>

    </div>
</template>

<script>

    export default {
        name: 'GameVariablesEdit',
        data () {
            return {
                variablesPresetID: null,
                variablesPreset: {},
                variables: [],

                errors: null,
                loading: {
                    update: false,
                },
            }
        },

        methods: {
            update() {
                this.loading.update = true;
                let data = {
                    variablesPreset: this.variablesPreset,
                };
                let that = this;
                this.axios.post('/api/backoffice/variables_presets/update', data)
                    .then((response) => {
                        that.$router.push({name: 'GameVariablesList'})
                    })
                    .catch(function (error) {
                        that.errors = error.response.data.errors;
                        that.loading.update = false;
                    })
            },
            getVariablesPreset() {
                let that = this;
                this.axios.get('/api/backoffice/variables_presets/get', {
                    params: {
                        id: this.variablesPresetID,
                    }
                }).then((response) => {
                    that.variablesPreset = response.data.variablesPreset;
                    this.$parent.additionalSection = {name: that.variablesPreset.name};
                    this.getVariables();
                })
            },
            getVariables() {
                let that = this;
                this.axios.get('/api/backoffice/get_variables').then((response) => {
                    console.log(response.data);
                    that.variables = response.data;
                })
            },
        },
        mounted() {
            this.variablesPresetID = this.$route.params.variablesPresetId;
            this.getVariablesPreset();
        }
    }
</script>


