<template>
    <div>

        <template v-if="$route.name === 'HostGamesControl'">
            <router-view></router-view>
        </template>
        <template v-else>
            <div class="ui top attached tabular menu" v-if="$route.name !== 'HostGamesControl'">

                <router-link
                    :to="{name: 'HostGamesList'}"
                    is="sui-menu-item"
                    :active="activeSection === 'HostGamesList'"
                    :content="$store.state.adminLanguage['games_list']"
                    v-on:click.native="setActiveSection('HostGamesList')"
                />

                <div class="right menu">
                    <router-link
                        v-if="$route.name === 'HostGamesList'"
                        :to="{name: 'HostGamesAdd'}"
                        is="sui-menu-item"
                        :active="activeSection === 'HostGamesAdd'"
                        v-on:click.native="setActiveSection('HostGamesAdd')"
                    >
                        <i class='add icon'></i>{{ $store.state.adminLanguage['add_game'] }}
                    </router-link>
                </div>
            </div>

            <div class="ui bottom attached segment padded">
                <router-view></router-view>
            </div>
        </template>



    </div>
</template>

<script>
    import SectionMixin from '../mixins/sectionMixin';

    export default {
        name: 'HostGames',
        mixins: [SectionMixin],
        data () {
            return {

            }
        },
        methods: {

        },
        mounted() {
            this.activeSection = this.$router.history.current.name;
        }
    }
</script>


