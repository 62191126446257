import Vue from 'vue'
import router from './router'
import store from './store'

import SuiVue from 'semantic-ui-vue';

import axios from 'axios'
import VueAxios from 'vue-axios'

import './scss/index.scss';

import App from './App.vue'

Vue.use(SuiVue);
Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
