<template>
    <div>

        <div class="ui top attached tabular menu">

            <router-link
                :to="{name: 'GameHostsList'}"
                is="sui-menu-item"
                :active="activeSection === 'GameHostsList'"
                :content="$store.state.adminLanguage['hosts_list']"
                v-on:click.native="setActiveSection('GameHostsList')"
            />
            <div class="right menu">
                <router-link
                    :to="{name: 'GameHostsAdd'}"
                    is="sui-menu-item"
                    :active="activeSection === 'GameHostsAdd'"
                    v-on:click.native="setActiveSection('GameHostsAdd')"
                >
                    <i class='add icon'></i>{{ $store.state.adminLanguage['add_host'] }}
                </router-link>
            </div>
        </div>

        <div class="ui bottom attached segment padded">
            <router-view></router-view>
        </div>

    </div>
</template>

<script>
    import SectionMixin from '../mixins/sectionMixin';

    export default {
        name: 'GameHosts',
        mixins: [SectionMixin],
        data () {
            return {

            }
        },
        methods: {

        },
        mounted() {

        }
    }
</script>


