<template>
    <div>

        <div class="ui top attached tabular menu">

            <router-link
                :to="{name: 'AdminLanguagesList'}"
                is="sui-menu-item"
                :active="activeSection === 'AdminLanguagesList'"
                :content="$store.state.adminLanguage['languages_list']"
                v-on:click.native="setActiveSection('AdminLanguagesList')"
            />

            <div class="right menu">
                <router-link
                    :to="{name: 'AdminLanguagesAdd'}"
                    is="sui-menu-item"
                    :active="activeSection === 'AdminLanguagesAdd'"
                    :content="$store.state.adminLanguage['add_language']"
                    v-on:click.native="setActiveSection('AdminLanguagesAdd')"
                />
            </div>

        </div>

        <div class="ui bottom attached segment padded">
            <router-view></router-view>
        </div>

    </div>
</template>

<script>
import SectionMixin from '../mixins/sectionMixin';

export default {
    name: 'AdminLanguages',
    mixins: [SectionMixin],
    data () {
        return {

        }
    },
    methods: {

    },
    mounted() {

    }
}
</script>


