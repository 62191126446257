import { Line } from 'vue-chartjs'

export default {
    extends: Line,
    props: ['lineChartData'],
    data: () => ({
        options: {
            layout: {
                padding: {
                    left: 10,
                    right: 10,
                    top: 10,
                    bottom: 10
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        suggestedMin: 0,
                        suggestedMax: 150,
                        stepSize: 50,
                        display: false
                    }
                }],
                xAxes: [{
                    gridLines: {
                        drawTicks: false,
                        display: false
                    }
                }]
            },
            plugins: {
                datalabels: {
                    display: false
                }
            }
        }
    }),

    methods: {
        render() {
            this.renderChart(
                this.lineChartData,
                this.options
            )
        },
    },

    // mounted () {
    //     let ctx = this.$refs.canvas.getContext('2d');
    //     let linechartBackground = ctx.createLinearGradient(0, 0, 0, 300);
    //     linechartBackground.addColorStop(0, 'rgba(105, 179, 58, 0.51)');
    //     linechartBackground.addColorStop(1, 'rgba(105, 179, 58, 0)');
    //     this.lineChartData.datasets.backgroundColor = linechartBackground;
    //
    //     this.renderChart(this.barChartData, this.options)
    // }

    mounted () {
        this.render();
    }
}
