<template>
    <div>
        <sui-form>

            <sui-message error visible v-if="errors">
                <sui-message-list>
                    <sui-message-item v-for="error in errors">
                        {{ error[0] }}
                    </sui-message-item>
                </sui-message-list>
            </sui-message>

            <sui-header>{{ $store.state.adminLanguage['main_info'] }}</sui-header>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['name2'] }}</label>
                <input
                    type="text"
                    v-model="language.name"
                />
            </sui-form-field>

            <sui-form-field width="two">
                <label>{{ $store.state.adminLanguage['code'] }}</label>
                <input
                    type="text"
                    v-model="language.code"
                />
            </sui-form-field>

            <sui-header>{{ $store.state.adminLanguage['lang_messages'] }}</sui-header>

            <sui-form-fields v-for="(message, key, index) in langMessages">

                <sui-form-field width="four">
                    <p>{{ message.original_value }}</p>
                </sui-form-field>

                <sui-form-field width="eight">
                    <input
                        type="text"
                        v-model="language.messages[message.id.toString()]"
                    />
                </sui-form-field>

            </sui-form-fields>

            <sui-header>Страны и города</sui-header>

            <template v-for="country in langNames.countries" v-if="langNames.countries.length">

                <sui-form-fields>
                    <sui-form-field width="four">
                        <p><b>{{ country.original_value }}</b></p>
                    </sui-form-field>

                    <sui-form-field width="eight">
                        <input
                            type="text"
                            v-model="language.names.countries[country.id]"
                        />
                    </sui-form-field>
                </sui-form-fields>

            </template>

            <sui-form-field>
                <br>
                <sui-button basic positive v-on:click="update()">{{ $store.state.adminLanguage['update'] }}</sui-button>
            </sui-form-field>
        </sui-form>

    </div>
</template>

<script>

    export default {
        name: 'GameLanguagesEdit',
        data () {
            return {
                language: {},
                langMessages: [],
                langNames: {},
                id: null,
                errors: null,
            }
        },

        methods: {
            update() {
                let data = {
                    language: this.language,
                };
                let that = this;
                this.axios.post('/api/backoffice/languages/update', data).then((response) => {
                    that.$router.push({name: 'GameLanguagesList'})
                })
            },
            getLanguage(id) {
                let that = this;
                this.axios.get('/api/backoffice/languages/get', {
                    params: {
                        id: id
                    }
                }).then((response) => {
                    that.language = response.data;
                    if (that.language.messages.length === 0) {
                        that.language.messages = {};
                    }
                })
            },
            getLangMessages() {
                this.axios.get('/api/backoffice/get_lang_messages')
                    .then((response) => {
                        this.langMessages = response.data;
                    });
            },
            getLangNames() {
                this.axios.get('/api/backoffice/get_lang_names')
                    .then((response) => {
                        this.langNames = response.data;
                    });
            },
        },
        mounted() {
            this.id = this.$route.params.id;
            this.getLangMessages();
            this.getLangNames();
            this.getLanguage(this.id);
        }
    }
</script>


