<template>
    <div>

        <sui-form>
            <sui-header>{{ $store.state.adminLanguage['main_info'] }}</sui-header>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['name2'] }}</label>
                <input
                    type="text"
                    :placeholder="$store.state.adminLanguage['name2']"
                    v-model="genre.name"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['genre_font'] }}</label>
                <input
                    type="text"
                    :placeholder="$store.state.adminLanguage['genre_font']"
                    v-model="genre.font"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>Интерес аудитории</label>
                <input
                    type="text"
                    :placeholder="'100'"
                    v-model="genre.value"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>Минимальный бюджет продвижения</label>
                <input
                    type="text"
                    :placeholder="'100'"
                    v-model="genre.min_promotion"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['genre_icon'] }}</label>
                <textarea
                    type="text"
                    :placeholder="$store.state.adminLanguage['genre_icon']"
                    v-model="genre.icon"
                ></textarea>
            </sui-form-field>

            <sui-form-field width="eight" class="backoffice-file-upload">
                <label>{{ $store.state.adminLanguage['genre_background'] }}</label>
                <input type="file" :ref="'background'" hidden v-on:change="uploadBackground($event)" />
                <img class="backoffice-background-upload__button" :src="genre.background" v-on:click="pickBackground()" />
            </sui-form-field>

            <sui-form-field>
                <br>
                <sui-button basic positive v-on:click="update()">{{ $store.state.adminLanguage['update'] }}</sui-button>
            </sui-form-field>
        </sui-form>

    </div>
</template>

<script>

    export default {
        name: 'GameGenresEdit',
        data () {
            return {
                genreID: null,
                genre: {},

                uploadingBackground: null,

                errors: null,
                loading: {
                    update: false,
                },
            }
        },

        methods: {
            update() {
                this.loading.update = true;
                let data = {
                    genre: this.genre,
                };
                let that = this;
                this.axios.post('/api/backoffice/genres/update', data)
                    .then((response) => {
                        that.$router.push({name: 'GameGenresList'})
                    })
                    .catch(function (error) {
                        that.errors = error.response.data.errors;
                        that.loading.update = false;
                    })
            },

            getGenre() {
                this.axios.get('/api/backoffice/genres/get', {
                    params: {
                        id: this.genreID,
                    }
                }).then((response) => {
                    this.genre = response.data.genre;
                })
            },

            pickBackground() {
                this.$refs['background'].click()
            },

            uploadBackground(event) {
                this.uploadingBackground = event.target.files[0];
                let data = new FormData();
                data.append('background', this.uploadingBackground);

                this.axios.post('/api/backoffice/upload_background', data)
                    .then((response) => {
                        this.genre.background = response.data;
                    })
            },

        },
        mounted() {
            this.genreID = this.$route.params.genreId;
            this.getGenre();
        }
    }
</script>


