<template>
    <div>
        <sui-form>
            <sui-header>{{ $store.state.adminLanguage['main_info'] }}</sui-header>

            <sui-form-field width="eight">
                <label>E-mail</label>
                <input
                    type="text"
                    placeholder="Email"
                    v-model="host.email"
                />
            </sui-form-field>

            <sui-form-fields>

                <sui-form-field width="eight">
                    <label>{{ $store.state.adminLanguage['name'] }}</label>
                    <input
                        type="text"
                        :placeholder="$store.state.adminLanguage['name']"
                        v-model="host.name"
                    />
                </sui-form-field>

                <sui-form-field width="eight">
                    <label>{{ $store.state.adminLanguage['company'] }}</label>
                    <input
                        type="text"
                        :placeholder="$store.state.adminLanguage['company']"
                        v-model="host.company"
                    />
                </sui-form-field>

            </sui-form-fields>

            <sui-form-fields>

                <sui-form-field width="eight">
                    <label>{{ $store.state.adminLanguage['password'] }}</label>
                    <input
                        type="text"
                        :placeholder="$store.state.adminLanguage['password']"
                        v-model="host.password"
                    />
                </sui-form-field>

                <sui-form-field width="eight">
                    <label>{{ $store.state.adminLanguage['password_confirm'] }}</label>
                    <input
                        type="text"
                        :placeholder="$store.state.adminLanguage['password_confirm']"
                        v-model="host.passwordConfirm"
                    />
                </sui-form-field>

            </sui-form-fields>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['description'] }}</label>
                <textarea rows="3" v-model="host.description"></textarea>
            </sui-form-field>

            <sui-form-field>
                <br>
                <sui-button basic positive v-on:click="update()">{{ $store.state.adminLanguage['update'] }}</sui-button>
            </sui-form-field>
        </sui-form>

    </div>
</template>

<script>

    export default {
        name: 'GameHostsEdit',
        data () {
            return {
                host: {},
                id: null,
            }
        },

        methods: {
            update() {
                let data = {
                    host: this.host,
                };
                let that = this;
                this.axios.post('/api/backoffice/hosts/update', data).then((response) => {
                    that.$router.push({name: 'GameHostsList'})
                })
            },
            getHost(id) {
                let that = this;
                this.axios.get('/api/backoffice/hosts/get', {
                    params: {
                        id: id
                    }
                }).then((response) => {
                    that.host = response.data;
                })
            },
        },
        mounted() {
            this.id = this.$route.params.id;
            this.getHost(this.id);
        }
    }
</script>


