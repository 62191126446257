<template>
    <div>

        <div class="preloader" v-if="isLoading">
            <img src="/img/backoffice/preloader.gif" />
        </div>
        <div v-else>
            <sui-table compact="very" striped>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>Email</sui-table-header-cell>
                        <sui-table-header-cell>{{ $store.state.adminLanguage['name'] }}</sui-table-header-cell>
                        <sui-table-header-cell>{{ $store.state.adminLanguage['company'] }}</sui-table-header-cell>
                        <sui-table-header-cell>{{ $store.state.adminLanguage['registration_date'] }}</sui-table-header-cell>
                        <sui-table-header-cell></sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>

                <sui-table-body>

                    <sui-table-row v-for="(item, index) of data">
                        <sui-table-cell>
                            <router-link is="sui-button" icon basic :to="{name: 'GameHost', params: {id: item.id}}">
                                {{ item.email }}
                            </router-link>
                        </sui-table-cell>

                        <sui-table-cell>
                            {{ item.name }}
                        </sui-table-cell>

                        <sui-table-cell>
                            {{ item.company }}
                        </sui-table-cell>

                        <sui-table-cell>
                            {{ new Date(item.created_at).toLocaleDateString() }}
                        </sui-table-cell>

                        <sui-table-cell text-align="right">
                            <router-link is="sui-button" icon basic :to="{name: 'GameHostsEdit', params: {id: item.id}}">
                                <sui-icon color="blue" name="edit" />
                            </router-link>

                            <sui-button icon basic v-on:click="deleteHost(item.id, index)">
                                <sui-icon color="red" name="close" />
                            </sui-button>
                        </sui-table-cell>

                    </sui-table-row>

                </sui-table-body>
            </sui-table>
        </div>

    </div>
</template>

<script>
    import CommonMixin from '../../mixins/commonMixin';
    import TableMixin from '../../mixins/tableMixin';

    export default {
        name: 'GameHostsList',
        mixins: [CommonMixin, TableMixin],
        data () {
            return {
                data: [],
                isLoading: true,
            }
        },

        methods: {
            getHostsList() {
                this.axios.get('/api/backoffice/hosts/list').then((response) => {
                    this.data = response.data;
                    this.isLoading = false;
                })
            },
            deleteHost(id, index) {
                let data = {
                    host: {
                        id: id,
                    },
                };
                let that = this;
                this.axios.post('/api/backoffice/hosts/delete', data).then((response) => {
                    that.$delete(that.data, index);
                })
            }
        },
        beforeMount() {
            this.getHostsList();
        },
    }
</script>


