<template>
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.00024" y="1" width="15" height="15" rx="5" stroke="#69B33A" stroke-width="2"/>
        <path v-if="checked" d="M12.6479 6.80327L7.70783 11.7292C7.50258 11.9335 7.22656 12.0392 6.95762 12.0392C6.68868 12.0392 6.41266 11.9335 6.20742 11.7292L4.31067 9.8476C3.89311 9.43182 3.89311 8.76235 4.31067 8.35362C4.72824 7.93785 5.40059 7.93785 5.81108 8.35362L6.95055 9.4882L11.1474 5.3093C11.565 4.89352 12.2374 4.89352 12.6479 5.3093C13.0654 5.72507 13.0654 6.39454 12.6479 6.80327Z" fill="black"/>
    </svg>
</template>

<script>
import './Checkbox.scss'

export default {
    name: 'checkbox',
    props: ['checked'],
    data () {
        return {

        }
    },
}
</script>
