import { Bar } from 'vue-chartjs'

export default {
    extends: Bar,
    props: ['barChartData'],
    data: () => ({

        options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 30,
                    bottom: 0
                }
            },
            legend: {
                display: false,
                labels: {
                    fontFamily: "'Raleway', sans-serif",
                    fontColor: '#FFFFFF'
                }
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        suggestedMin: 0,
                        stepSize: 25,
                        display: false
                    }
                }],
                xAxes: [{
                    ticks: {
                        fontFamily: "'Raleway', sans-serif",
                        fontSize: 12,
                        fontColor: '#FFFFFF',
                        padding: 20,
                    },
                    gridLines: {
                        drawTicks: false,
                        display: false
                    }
                }]
            },
            plugins: {
                datalabels: {
                    align: 'end',
                    anchor: 'end',
                    // backgroundColor: function(context) {
                    //     return context.dataset.backgroundColor;
                    // },
                    borderRadius: 4,
                    color: 'white',
                    formatter: function(value){
                        return value ;
                    }
                }
            }
        }

    }),

    methods: {
        render() {
            this.renderChart(this.barChartData, this.options);
        },
    },

    mounted () {
        let context = this.$refs.canvas.getContext('2d');
        let background = context.createLinearGradient(0, 0, 0, 300);
        background.addColorStop(0, 'rgb(87, 87, 87');
        background.addColorStop(1, 'rgba(44, 44, 44, 1)');
        this.barChartData.datasets[0].backgroundColor = background;
        this.barChartData.datasets.forEach(function (dataset) {
            context.fillText(dataset.value, dataset.x, dataset.y - 5);
        });
        this.render();
    }
}
