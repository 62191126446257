<template>
    <div></div>
</template>

<script>

    export default {
        name: 'Dashboard',
        data () {
            return {
                user: null,
            }
        },

        methods: {

        },
        beforeMount() {

        },
        mounted () {
            this.user = this.$store.getters.getUser;
            if (this.user.role === 'host') {
                this.$router.push({name: 'HostGamesList'})
            }
            if (this.user.role === 'admin') {
                this.$router.push({name: 'GameHostsList'})
            }
        }
    }
</script>


