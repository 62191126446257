<template>
    <div class="login">
        <sui-grid centered vertical-align="middle">
            <sui-grid-column>

                <h2 is="sui-header" image>
                    <game-logo
                        :size="[140, 50]"
                        :lang="'ru'"
                    ></game-logo>
                </h2>

                <sui-form>
                    <sui-segment stacked>

                        <sui-message error visible v-if="errors">
                            <sui-message-list>
                                <sui-message-item v-for="error in errors">
                                    {{ error[0] }}
                                </sui-message-item>
                            </sui-message-list>
                        </sui-message>

                        <sui-form-field>
                            <sui-input
                                type="email"
                                placeholder="E-mail"
                                icon="user"
                                icon-position="left"
                                v-model="credentials.email"
                                name="email"
                            />
                        </sui-form-field>
                        <sui-form-field>
                            <sui-input
                                type="password"
                                placeholder="Пароль"
                                icon="lock"
                                icon-position="left"
                                v-model="credentials.password"
                                name="password"
                            />
                        </sui-form-field>

                        <sui-button size="large" color="red" fluid v-on:click.prevent="login()">Войти</sui-button>
                    </sui-segment>
                </sui-form>
            </sui-grid-column>
        </sui-grid>
    </div>
</template>

<script>
    import GameLogo from "@/components/GameLogo/GameLogo";

    export default {
        name: 'Login',
        components: {
            GameLogo,
        },
        data () {
            return {
                errors: null,
                credentials: {
                    email: '',
                    password: '',
                },

            }
        },
        methods: {
            login() {
                this.error = null;
                let that = this;
                this.axios.get('/sanctum/csrf-cookie').then(response => {
                    that.axios.post('/login', that.credentials)
                        .then((response) => {
                            that.$router.push('/');
                        })
                        .catch(function (error) {
                            that.errors = error.response.data.errors;
                        })
                });
            },
        },
    }
</script>
