<template>
    <div>

        <div class="ui top attached tabular menu">

            <router-link
                :to="{name: 'GameVariablesList'}"
                is="sui-menu-item"
                :active="activeSectionRoute === 'GameVariablesList'"
                :content="$store.state.adminLanguage['presets_list']"
                v-on:click.native="setActiveSection('GameVariablesList')"
            />

            <div class="right menu">
                <router-link
                    :to="{name: 'GameVariablesAdd'}"
                    is="sui-menu-item"
                    :active="activeSectionRoute === 'GameVariablesAdd'"
                    v-on:click.native="setActiveSection('GameVariablesAdd')"
                >
                    <i class='add icon'></i>{{ $store.state.adminLanguage['add_preset'] }}
                </router-link>
            </div>
        </div>

        <div class="ui bottom attached segment padded">
            <router-view></router-view>
        </div>

    </div>
</template>

<script>

    import SectionMixin from "../mixins/sectionMixin";

    export default {
        name: 'GameVariables',
        mixins: [SectionMixin],
        data () {
            return {
                id: null,

            }
        },
        computed: {
            activeSectionRoute: function() {
                let route;
                if (this.activeSection) {
                    route = this.activeSection;
                } else {
                    route = this.$router.history.current.name;
                }

                return route;
            },
        },
        methods: {

        },
        beforeMount() {

        },
        mounted () {

        }
    }
</script>


