<template>
    <div>
        <sui-form>

            <sui-message error visible v-if="errors">
                <sui-message-list>
                    <sui-message-item v-for="error in errors">
                        {{ error[0] }}
                    </sui-message-item>
                </sui-message-list>
            </sui-message>

            <sui-header>{{ $store.state.adminLanguage['main_info'] }}</sui-header>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['name2'] }}</label>
                <input
                    type="text"
                    v-model="language.name"
                />
            </sui-form-field>

            <sui-form-field width="two">
                <label>{{ $store.state.adminLanguage['code'] }}</label>
                <input
                    type="text"
                    v-model="language.code"
                />
            </sui-form-field>

            <sui-header>{{ $store.state.adminLanguage['lang_messages'] }}</sui-header>

            <sui-form-fields v-for="(message, key, index) in langMessages">

                <sui-form-field width="four">
                    <p>{{ message.original_value }}</p>
                </sui-form-field>

                <sui-form-field width="eight">
                    <input
                        type="text"
                        v-model="language.messages[message.id.toString()]"
                    />
                </sui-form-field>

            </sui-form-fields>

            <sui-form-field>
                <br>
                <sui-button basic positive v-on:click="update()">{{ $store.state.adminLanguage['update'] }}</sui-button>
            </sui-form-field>
        </sui-form>

    </div>
</template>

<script>

export default {
    name: 'GameLanguagesEdit',
    data () {
        return {
            language: {},
            langMessages: [],
            langNames: {},
            id: null,
            errors: null,
        }
    },

    methods: {
        update() {
            let data = {
                language: this.language,
            };
            let that = this;
            this.axios.post('/api/backoffice/admin_languages/update', data).then((response) => {
                that.$router.push({name: 'AdminLanguagesList'})
            })
        },
        getLanguage(id) {
            let that = this;
            this.axios.get('/api/backoffice/admin_languages/get', {
                params: {
                    id: id
                }
            }).then((response) => {
                that.language = response.data;
                if (that.language.messages.length === 0) {
                    that.language.messages = {};
                }
            })
        },
        getLangMessages() {
            this.axios.get('/api/backoffice/get_lang_admin_messages')
                .then((response) => {
                    this.langMessages = response.data;
                });
        },
    },
    mounted() {
        this.id = this.$route.params.id;
        this.getLangMessages();
        this.getLanguage(this.id);
    }
}
</script>


