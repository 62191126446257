<template>
    <div>
        <sui-form>

            <sui-message error visible v-if="errors">
                <sui-message-list>
                    <sui-message-item v-for="error in errors">
                        {{ error[0] }}
                    </sui-message-item>
                </sui-message-list>
            </sui-message>

            <sui-header>{{ $store.state.adminLanguage['main_info'] }}</sui-header>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['name2'] }}</label>
                <input
                    type="text"
                    v-model="language.name"
                />
            </sui-form-field>

            <sui-form-field width="two">
                <label>{{ $store.state.adminLanguage['code'] }}</label>
                <input
                    type="text"
                    v-model="language.code"
                />
            </sui-form-field>

            <sui-header>{{ $store.state.adminLanguage['lang_messages'] }}</sui-header>

            <sui-form-fields v-for="(message, key, index) in langMessages">

                <sui-form-field width="four">
                    <p>{{ message.original_value }}</p>
                </sui-form-field>

                <sui-form-field width="eight">
                    <input
                        type="text"
                        v-model="language.messages[message.id.toString()]"
                    />
                </sui-form-field>

            </sui-form-fields>

            <sui-form-field>
                <br>
                <sui-button positive v-bind="{loading: loading.add}" v-on:click.prevent="add()">{{ $store.state.adminLanguage['add'] }}</sui-button>
            </sui-form-field>
        </sui-form>

    </div>
</template>

<script>

export default {
    name: 'AdminLanguagesAdd',
    data () {
        return {
            language: {
                name: '',
                code: '',
                messages: {},
            },
            langMessages: [],
            errors: null,
            loading: {
                add: false,
            }
        }
    },

    methods: {
        add() {
            this.loading.add = true;
            this.errors = null;
            let data = this.language;
            let that = this;
            this.axios.post('/api/backoffice/admin_languages/add', data)
                .then((response) => {
                    that.$router.push({name: 'AdminLanguagesList'})
                })
                .catch(function (error) {
                    that.errors = error.response.data.errors;
                    that.loading.add = false;
                })
        },
        getLangMessages() {
            this.axios.get('/api/backoffice/get_lang_admin_messages')
                .then((response) => {
                    this.langMessages = response.data;
                });
        },
    },
    mounted() {
        this.getLangMessages();
    },
}
</script>


