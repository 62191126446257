<template>
    <div>

        <div class="ui top attached tabular menu">

            <router-link
                :to="{name: 'GameCountriesList'}"
                is="sui-menu-item"
                :active="activeSectionRoute === 'GameCountriesList'"
                :content="$store.state.adminLanguage['countries_list']"
                v-on:click.native="setActiveSection('GameCountriesList')"
            />

            <div class="right menu">
                <router-link
                    :to="{name: 'GameCountriesAdd'}"
                    is="sui-menu-item"
                    :active="activeSectionRoute === 'GameCountriesAdd'"
                    v-on:click.native="setActiveSection('GameCountriesAdd')"
                >
                    <i class='add icon'></i>{{ $store.state.adminLanguage['add_country'] }}
                </router-link>
            </div>
        </div>

        <div class="ui bottom attached segment padded">
            <router-view></router-view>
        </div>

    </div>
</template>

<script>
    import SectionMixin from '../mixins/sectionMixin';

    export default {
        name: 'GameCountries',
        mixins: [SectionMixin],
        data () {
            return {

            }
        },

        computed: {
            activeSectionRoute: function() {
                let route;
                if (this.activeSection) {
                    route = this.activeSection;
                } else {
                    route = this.$router.history.current.name;
                }

                return route;
            },
        },

        methods: {

        },
        mounted() {

        }
    }
</script>


