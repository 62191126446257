<template>
    <section class="help">
        <div class="container">
            <div class="guide">
                <div class="guide__contents">
                    <div class="guide__title">
                        <div class="caption">
                            Виртуальный фон
                        </div>
                    </div>
                    <div class="guide__items">
                        <div class="guide__line"></div>
                        <div class="guide__item">
                            <div class="guide__caption">
                                <div class="caption">
                                    Папка с картинками для виртуальных фонов:
                                </div>
                            </div>
                            <div class="guide__link">
                                <div class="caption">
                                    https://cutt.ly/saleYfe
                                </div>
                            </div>
                            <div class="guide__button button">
                                <div class="button__contents">
                                    <div class="button__icon">
                                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.7509 0.0188902C12.6756 0.0188902 5.46456 0 5.46456 0C4.17974 0 3.03577 1.17842 3.03577 2.42878L2.33378 2.44517C1.0498 2.44517 0 3.6072 0 4.85757V17.0012C0 18.2513 1.14397 19.43 2.42878 19.43H11.5367C12.8215 19.43 13.9654 18.2516 13.9654 17.0012H14.5727C15.8575 17.0012 17.0015 15.8228 17.0015 14.5724V4.87201L12.7509 0.0188902ZM11.5367 18.2157H2.42878C1.79124 18.2157 1.21453 17.6201 1.21453 17.0012V4.85757C1.21453 3.95417 1.95764 3.64331 3.03605 3.64331V14.5727C3.03605 15.8228 4.18002 17.0015 5.46483 17.0015C5.46483 17.0015 12.0481 16.9948 12.7565 16.9948C12.7565 17.7315 12.2731 18.2157 11.5367 18.2157ZM12.7509 12.7576H7.28635C6.95105 12.7576 6.67909 12.4862 6.67909 12.1509C6.67909 11.8158 6.95105 11.5444 7.28635 11.5444H12.7509C13.0862 11.5444 13.3582 11.8158 13.3582 12.1509C13.3582 12.4862 13.0862 12.7576 12.7509 12.7576ZM12.7509 9.72458H7.28635C6.95105 9.72458 6.67909 9.45317 6.67909 9.11815C6.67909 8.78285 6.95105 8.51172 7.28635 8.51172H12.7509C13.0862 8.51172 13.3582 8.78312 13.3582 9.11815C13.3582 9.45262 13.0862 9.72458 12.7509 9.72458ZM13.9652 4.85757C13.3184 4.85757 12.7509 4.27419 12.7509 3.64331C12.7509 3.64331 12.7509 2.41323 12.7509 1.23342V1.23231L15.787 4.85785H13.9652V4.85757Z" fill="#69838F"/>
                                        </svg>
                                    </div>
                                    <div class="button__caption">
                                        скопировать
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="guide__line"></div>
                        <div class="guide__item">
                            <div class="guide__caption">
                                <div class="caption">
                                    Папка с картинками для виртуальных фонов:
                                </div>
                            </div>
                            <div class="guide__link">
                                <div class="caption">
                                    https://cutt.ly/saleYfe
                                </div>
                            </div>
                            <div class="guide__button button">
                                <div class="button__contents">
                                    <div class="button__icon">
                                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.7509 0.0188902C12.6756 0.0188902 5.46456 0 5.46456 0C4.17974 0 3.03577 1.17842 3.03577 2.42878L2.33378 2.44517C1.0498 2.44517 0 3.6072 0 4.85757V17.0012C0 18.2513 1.14397 19.43 2.42878 19.43H11.5367C12.8215 19.43 13.9654 18.2516 13.9654 17.0012H14.5727C15.8575 17.0012 17.0015 15.8228 17.0015 14.5724V4.87201L12.7509 0.0188902ZM11.5367 18.2157H2.42878C1.79124 18.2157 1.21453 17.6201 1.21453 17.0012V4.85757C1.21453 3.95417 1.95764 3.64331 3.03605 3.64331V14.5727C3.03605 15.8228 4.18002 17.0015 5.46483 17.0015C5.46483 17.0015 12.0481 16.9948 12.7565 16.9948C12.7565 17.7315 12.2731 18.2157 11.5367 18.2157ZM12.7509 12.7576H7.28635C6.95105 12.7576 6.67909 12.4862 6.67909 12.1509C6.67909 11.8158 6.95105 11.5444 7.28635 11.5444H12.7509C13.0862 11.5444 13.3582 11.8158 13.3582 12.1509C13.3582 12.4862 13.0862 12.7576 12.7509 12.7576ZM12.7509 9.72458H7.28635C6.95105 9.72458 6.67909 9.45317 6.67909 9.11815C6.67909 8.78285 6.95105 8.51172 7.28635 8.51172H12.7509C13.0862 8.51172 13.3582 8.78312 13.3582 9.11815C13.3582 9.45262 13.0862 9.72458 12.7509 9.72458ZM13.9652 4.85757C13.3184 4.85757 12.7509 4.27419 12.7509 3.64331C12.7509 3.64331 12.7509 2.41323 12.7509 1.23342V1.23231L15.787 4.85785H13.9652V4.85757Z" fill="#69838F"/>
                                        </svg>
                                    </div>
                                    <div class="button__caption">
                                        скопировать
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="guide__line"></div>
                    </div>
                </div>
            </div>
            <div class="tutorial">
                <div class="tutorial__contents">
                    <div class="tutorial__title">
                        Алгоритм действий
                    </div>
                    <div class="tutorial__list">
                        <div class="tutorial__item">
                            <div class="tutorial__number">
                                1
                            </div>
                            <div class="tutorial__caption">
                                Раскидываем игроков по залам (на 7 минут)
                            </div>
                        </div>
                        <div class="tutorial__item">
                            <div class="tutorial__number">
                                2
                            </div>
                            <div class="tutorial__caption">
                                Игроки заполняют данные и возвращаются из залов
                            </div>
                        </div>
                        <div class="tutorial__item">
                            <div class="tutorial__number">
                                3
                            </div>
                            <div class="tutorial__caption">
                                Жмем «ПРЕЗЕНТАЦИЯ ФИЛЬМОВ»
                            </div>
                        </div>
                        <div class="tutorial__item">
                            <div class="tutorial__number">
                                4
                            </div>
                            <div class="tutorial__caption">
                                Выводим на экран вкладку «ФИЛЬМЫ» <br/>
                                <span class="thin">
                                Игроки презентуют фильмы (1 минута на каждую речь) Раскидываем игроков по залам (на 1 минуту)
                                </span>
                            </div>
                        </div>
                        <div class="tutorial__item">
                            <div class="tutorial__number">
                                5
                            </div>
                            <div class="tutorial__caption">
                                Жмем «ГОЛОСОВАНИЕ»<br/>
                                <span class="thin">
                                    Игроки голосуют за фильмы и возвращаются из залов Ведущий в это время оценивает контракты
                                </span>
                            </div>
                        </div>
                        <div class="tutorial__item">
                            <div class="tutorial__number">
                                6
                            </div>
                            <div class="tutorial__caption">
                                Жмем «СТАТИСТИКА»
                            </div>
                        </div>
                        <div class="tutorial__item">
                            <div class="tutorial__number">
                                7
                            </div>
                            <div class="tutorial__caption">
                                Переключаемся на следующий сезон
                            </div>
                        </div>
                        <div class="tutorial__item">
                            <div class="tutorial__number">
                                8
                            </div>
                            <div class="tutorial__caption">
                                Выводим на экран вкладку «СВОДКА», озвучиваем информацию
                            </div>
                        </div>
                        <div class="tutorial__item">
                            <div class="tutorial__number">
                                9
                            </div>
                            <div class="tutorial__caption">
                                Переходим к п. 2
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'control-guide',
    data () {
        return {

        }
    },
}
</script>
