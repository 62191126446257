<template>
    <div>
        <sui-form>
            <sui-header>{{ $store.state.adminLanguage['main_info'] }}</sui-header>

            <sui-form-field width="eight">
                <label>{{ $store.state.adminLanguage['name2'] }}</label>
                <input
                    type="text"
                    :placeholder="$store.state.adminLanguage['name2']"
                    v-model="country.name"
                />
            </sui-form-field>

            <sui-form-field width="eight">
                <label>Логотип</label>
                <textarea
                    type="text"
                    v-model="country.logo"
                ></textarea>
            </sui-form-field>

            <sui-form-field>
                <br>
                <sui-button basic positive v-on:click="add()">{{ $store.state.adminLanguage['add'] }}</sui-button>
            </sui-form-field>
        </sui-form>

    </div>
</template>

<script>

    export default {
        name: 'GameCountriesAdd',
        data () {
            return {
                country: {},
            }
        },

        methods: {
            add() {
                let data = {
                    country: this.country,
                };
                let that = this;
                this.axios.post('/api/backoffice/country_presets/add', data).then((response) => {
                    that.$parent.activeSection = 'GameCountriesList';
                    that.$router.push({name: 'GameCountriesEdit', params: { countryId: response.data.id }})
                })
            },
        },
    }
</script>


